/* Asap */
@font-face {
    font-family: "Asap Regular";
    font-style: normal;
    font-weight: 400;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYkqUcKWmW.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

@font-face {
    font-family: "Asap Medium";
    font-style: normal;
    font-weight: 500;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYoKUcKWmW.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

@font-face {
    font-family: "Asap SemiBold";
    font-style: normal;
    font-weight: 600;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYTKIcKWmW.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

@font-face {
    font-family: "Asap Bold";
    font-style: normal;
    font-weight: 700;
    font-stretch: 100%;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/asap/v30/KFOOCniXp96a4Tc2DaTeuDAoKsE617JFc49knOIYdjTYdaIcKWmW.woff2)
        format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

/* Open Sans */

@font-face {
    font-family: "K2D Thin";
    font-style: normal;
    font-weight: 100;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0ErE5UutLM.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

@font-face {
    font-family: "K2D ExtraLight";
    font-style: normal;
    font-weight: 200;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0ErE5UutLM.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

@font-face {
    font-family: "K2D Light";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0ErE5UutLM.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

@font-face {
    font-family: "K2D Regular";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0ErE5UutLM.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

@font-face {
    font-family: "K2D Medium";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0ErE5UutLM.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}
@font-face {
    font-family: "K2D SemiBold";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0ErE5UutLM.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}
@font-face {
    font-family: "K2D Bold";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0ErE5UutLM.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

@font-face {
    font-family: "K2D ExtraBold";
    font-style: normal;
    font-weight: 800;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/k2d/v11/J7aRnpF2V0ErE5UutLM.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304,
        U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF,
        U+FFFD;
}

/* Style */
html {
    scroll-behavior: smooth;
    font-size: 15px;
    overflow-x: hidden;
}

@media (max-width: 900px) {
    html {
        font-size: 14px;
    }
}

@media (max-width: 550px) {
    html {
        font-size: 12px;
    }
}

body {
    margin: 0;
    padding: 0;
}

::-moz-selection {
    /* Code for Firefox */
    background: #ffd526;
}

::selection {
    background: #ffd526;
}

.MuiInputLabel-root {
    color: #000;
}

/* RD STATION FORM  */

#form-1-drop-site-accc019797d4d17a0e9c h1,
#form-1-drop-site-accc019797d4d17a0e9c button,
#form-1-drop-site-accc019797d4d17a0e9c input,
#form-1-drop-site-accc019797d4d17a0e9c strong {
    transition: all 0.2s ease;
    font-family: "Asap Regular" !important;
}

#form-1-drop-site-accc019797d4d17a0e9c input {
    outline: none !important;
}

#form-1-drop-site-accc019797d4d17a0e9c button:hover {
    color: #fff;
}
